import React from "react"
import { useSearchParam } from 'react-use';
import { graphql, navigate } from 'gatsby';

import { getProductUrl, getUrl } from '../../utils';

const Redirect = ({ data, pageContext }) => {
  const { products } = data;
  const { locale } = pageContext;
  const productId = useSearchParam('id');

  React.useEffect(() => {
    if (!productId) {
      return;
    }

    const product = products?.nodes.find(p => p.shopifyHumanId === productId);
    const url = product ?
      getProductUrl(product.handle, locale, product.defaultVariant) :
      getUrl('/', locale);
    
    navigate(url);
  }, [products, productId, locale]);

  return null;
}
export const query = graphql`
  query Products {
    products: allShopifyProduct {
      nodes {
        handle
        shopifyHumanId
        locale
      }
    }
  }
`;

export default Redirect;
